import React, { Component } from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import dcStatehood from "../images/images/DC-Statehood-Logo.png";
import jjstraight from "../images/images/jj-bw-straight-sq.jpg";
import jjsmile from "../images/images/jj-bw-smile-sq.jpg";
// import rangertab from "../images/images/rangertabsmall.png";
import Collapsible from "react-collapsible";

export default class AboutPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHovered: false,
        };
    }

    toggleHovered = () => {
        this.setState({ isHovered: !this.state.isHovered });
    };

    render() {
        return (
            <Layout>
                <SEO
                    keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
                    title="About"
                />

                <section className="flex flex-col items-center">
                    <div className="flex justify-center align-center">
                        <div>
                            <blockquote className="pl-4 font-serif leading-loose text-justify border-l-4 border-gray-900">
                                No taxation without representation!
                            </blockquote>

                            <cite className="block mt-4 text-xs font-bold text-right uppercase">
                                – Every Red Blooded American
                            </cite>
                        </div>

                        <figure className="w-20 ml-20">
                            <a
                                href="https://statehood.dc.gov/page/why-statehood-dc"
                                target="_blank"
                                rel="noopener noreferrer">
                                <img alt="DC Statehood" src={dcStatehood} />
                            </a>
                        </figure>
                    </div>

                    <hr className="w-1/2 m-4 border-blue-700" />

                    <div className="flex justify-center align-center">
                        <img
                            alt="jj smile"
                            onMouseEnter={this.toggleHovered}
                            onMouseLeave={this.toggleHovered}
                            src={this.state.isHovered ? jjstraight : jjsmile}
                            className="w-20 h-20 rounded-full mr-16"
                        />
                        <div>
                            <blockquote className="pl-4 font-serif leading-loose text-justify border-l-4 border-gray-900">
                                Hi, my name is JJ.
                            </blockquote>

                            <cite className="block mt-4 text-xs font-bold text-right uppercase">
                                – JJ Clements
                            </cite>
                        </div>
                    </div>

                    <hr className="w-full m-4 border-blue-700" />

                    <div>
                        <Collapsible
                            classParentString="mt-4"
                            trigger={"Favorite Cities <"}
                            triggerWhenOpen={`Favorite Cities >`}
                            transitionTime={1}
                            triggerClassName="text-red-500 cursor-pointer"
                            triggerOpenedClassName="text-green-700 font-bold cursor-pointer"
                            contentInnerClassName="text-black">
                            <ol className="ml-8 list-decimal">
                                <li>New York City</li>
                                <li>Amsterdam</li>
                                <li>Washington DC</li>
                                <li>Seoul</li>
                                <li>Hong Kong</li>
                                <li>Chicago</li>
                                <li>Tokyo</li>
                                <li>London</li>
                                <li>Sydney</li>
                                <li>Copenhagen</li>
                            </ol>
                        </Collapsible>
                        <Collapsible
                            classParentString="mt-4"
                            trigger={"Favorite DJs <"}
                            triggerWhenOpen={`Favorite DJs >`}
                            transitionTime={1}
                            triggerClassName="text-red-500 cursor-pointer"
                            triggerOpenedClassName="text-green-700 font-bold cursor-pointer"
                            contentInnerClassName="text-black">
                            <ol className="ml-8 list-decimal">
                                <li>Zhu</li>
                                <li>Jan Blomqvist</li>
                                <li>HVOB</li>
                                <li>Zimmer</li>
                                <li>Lane 8</li>
                                <li>Elderbrook</li>
                                <li>Kendl</li>
                                <li>Four Tet</li>
                                <li>Amtrac</li>
                                <li>Monolink</li>
                            </ol>
                        </Collapsible>
                        <Collapsible
                            classParentString="mt-4"
                            trigger={"Favorite Parks <"}
                            triggerWhenOpen={`Favorite Parks >`}
                            transitionTime={1}
                            triggerClassName="text-red-500 cursor-pointer"
                            triggerOpenedClassName="text-green-700 font-bold cursor-pointer"
                            contentInnerClassName="text-black">
                            <ol className="ml-8 list-decimal">
                                <li>Washington Square Park (NYC)</li>
                                <li>Sarah D. Roosevelt Park (NYC)</li>
                                <li>Bryant Park (NYC)</li>
                                <li>Fort Greene Park (Brooklyn)</li>
                                <li>Logan Circle (DC)</li>
                                <li>Central Park (NYC)</li>
                                <li>Millennium Park (Chicago)</li>
                                <li>Vondelpark (Amsterdam)</li>
                                <li>National Mall (DC)</li>
                                <li>Centennial Park (Nashville)</li>
                            </ol>
                        </Collapsible>
                        <Collapsible
                            classParentString="mt-4"
                            trigger={"JJ Facts <"}
                            triggerWhenOpen={`JJ Facts >`}
                            transitionTime={1}
                            triggerClassName="text-red-500 cursor-pointer"
                            triggerOpenedClassName="text-green-700 font-bold cursor-pointer"
                            contentInnerClassName="text-black">
                            <ol className="ml-8 list-disc">
                                <li>Grew up an Army brat 🏠</li>
                                <li>
                                    Own a beautiful, vintage 1987 Centurion
                                    Ironman Expert Dave Scott bicycle 🚲
                                </li>
                                <li>
                                    Certified Royal Yachting Association (RYA)
                                    Yachtmaster ⛵
                                </li>
                                <li>
                                    Ranger tab. &ldquo;Rangers Lead the
                                    Way!&rdquo;
                                </li>
                                <li>OneWheeler -o- Float Life.</li>
                            </ol>
                        </Collapsible>
                    </div>
                </section>
            </Layout>
        );
    }
}

// function AboutPage() {
//     return (
//         <Layout>
//             <SEO
//                 keywords={[`gatsby`, `tailwind`, `react`, `tailwindcss`]}
//                 title="About"
//             />

//             <section className="flex flex-col items-center">
//                 <div className="flex justify-center align-center">
//                     <div>
//                         <blockquote className="pl-4 font-serif leading-loose text-justify border-l-4 border-gray-900">
//                             No taxation without representation!
//                         </blockquote>

//                         <cite className="block mt-4 text-xs font-bold text-right uppercase">
//                             – Every Red Blooded American
//                         </cite>
//                     </div>

//                     <figure className="w-20 ml-20">
//                         <a
//                             href="https://statehood.dc.gov/page/why-statehood-dc"
//                             target="_blank"
//                             rel="noopener noreferrer">
//                             <img alt="DC Statehood" src={dcStatehood} />
//                         </a>
//                     </figure>
//                 </div>
//                 <br />
//                 <hr className="w-full m-10 border-blue-700" />
//                 <br />
//                 <div>
//                     <Collapsible
//                         className="text-xl border cursor-pointer"
//                         trigger="Favorite Cities">
//                         <ol>
//                             <li>New York City</li>
//                         </ol>

//                     </Collapsible>
//                 </div>
//             </section>
//         </Layout>
//     );
// }

// export default AboutPage;
